const parseError = (error) => {
  if (error.response && error.response.status !== 404) {
    return {
      code: error.code,
      message: error.response.data.errors[0].message
    };
  } else if (error.message) {
    return {
      name: error.name,
      message: error.message
    };
  }

  return {
    name: 'UnexpectedError',
    message: 'An unexpected error occurred, please try again later.'
  };
};

export default parseError;
