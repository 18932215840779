<template>
  <div>
    <a class="sidebar-button" :class="{ active: active }" :href="href" :target="external" @click="navigate">
      <span :class="icon" class="fa-solid fa-lg"></span>
      <div class="sidebar-button__text">{{ title }}</div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    href: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    },
    external: {
      type: String,
      required: false
    },
    navigate: {
      type: Function,
      required: false,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {}
    }
  }
};
</script>

<style scoped>
.sidebar-button {
  position: relative;
  display: flex;
  width: 100%;
  height: 5rem;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  border-top: 1px solid rgba(10, 10, 10, 0.13);
  border-bottom: 1px solid rgba(10, 10, 10, 0.13);
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.sidebar-button:hover,
.sidebar-button:focus,
.sidebar-button.active {
  background-color: #408046;
  transition: background-color 0.3s ease-in-out;
}

.sidebar-button.active::before {
  position: absolute;
  left: 0;
  top: 5%;
  content: '';
  height: 90%;
  width: 0.25rem;
  background-color: #ffffff;
}

.sidebar-button:link,
.sidebar-button:visited,
.sidebar-button:hover {
  color: #ffffff;
  text-decoration: none;
}

.sidebar-button span {
  line-height: 1.33333em;
}

.sidebar-button__text {
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1.1;
  margin-top: 0.25rem;
}
</style>
