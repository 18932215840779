import Axios from 'axios';
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';

import config from '@/config';

const axios = Axios.create({
  baseURL: config.API_URL
});

axios.interceptors.request.use(async function (config) {
  const token = await getAuth().verifyToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  config.headers['rb-client'] = 'Dashboard';

  return config;
});

axios.interceptors.response.use(
  async function (response) {
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (error.response) {
      // TODO If JWT was expired it might not return 401. Instead it would likely return 403?
      // Added a check for requests coming from the Login page as we need to allow the response to be displayed to the user
      if (error.response.status === 401 && window.location.pathname !== '/login') {
        window.location.href = '/logout';
      }
    }

    return Promise.reject(error);
  }
);

export default axios;
