import ReportServiceV2 from '@/lib/report-service-v2';
import parseError from '@/lib/parse-error';
import { downloadFormattedCSV } from '@/lib/download-file';
import { subDays } from 'date-fns';

const raffleSalesReport = {
  state: () => ({
    report: {},
    loading: false,
    downloading: false,
    error: null,
    page: 0,
    pageSize: 10,
    totalRecords: 0,
    filters: {
      status: null,
      fromDate: subDays(new Date(), 30).toISOString(),
      toDate: null,
      sortDir: 'desc',
      sortBy: 'endDate'
    }
  }),

  getters: {
    getTotalPages(state) {
      return Math.ceil(parseInt(state.totalRecords, 10) / parseInt(state.pageSize, 10)) || 1;
    }
  },

  mutations: {
    SET_RAFFLE_SALES_REPORT: (state, data) => {
      state.report = data;
    },
    SET_LOADING: (state, data) => {
      state.loading = data;
    },
    SET_ERROR: (state, error) => {
      state.error = error;
    },
    SET_CURRENT_PAGE: (state, page) => {
      state.page = page;
    },
    SET_TOTAL_RECORDS: (state, count) => {
      state.totalRecords = count;
    },
    SET_FILTERS: (state, filters) => {
      state.filters = { ...state.filters, ...filters };
    },
    SET_DOWNLOADING_REPORT: (state, downloading) => {
      state.downloading = downloading;
    },
    CLEAR_FILTERS: (state) => {
      state.filters = {
        status: null,
        fromDate: subDays(new Date(), 30).toISOString(),
        toDate: null,
        sortDir: 'desc',
        sortBy: 'endDate'
      };
    }
  },

  actions: {
    getRaffleSalesReport: async ({ commit, state, rootState }) => {
      try {
        commit('SET_LOADING', true);

        const params = {
          page: state.page,
          pageSize: state.pageSize,
          organizationId: rootState.user.organizationUuid,
          ...state.filters
        };

        // This endpoint only accepts 'pending', not 'draft'
        if (params.status === 'draft') {
          params.status = 'pending';
        }

        const data = await ReportServiceV2.listRaffleSales(params);

        if (data.data) {
          commit('SET_RAFFLE_SALES_REPORT', data.data);
          commit('SET_TOTAL_RECORDS', data.count);
        }
      } catch (error) {
        const formattedError = parseError(error).message;
        commit('SET_ERROR', formattedError);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    downloadRaffleSalesReport: async ({ commit, state, rootState }) => {
      try {
        commit('SET_DOWNLOADING_REPORT', true);
        const params = {
          pageSize: state.totalRecords,
          organizationId: rootState.user.organizationUuid,
          ...state.filters
        };

        if (params.status === 'draft') {
          params.status = 'pending';
        }

        const data = await ReportServiceV2.listRaffleSales(params, 'text/csv');
        downloadFormattedCSV(`raffle_sales_${Date.now()}.csv`, data);
      } catch (error) {
        const formattedError = parseError(error).message;
        commit('SET_ERROR', formattedError);
      } finally {
        commit('SET_LOADING', false);
        commit('SET_DOWNLOADING_REPORT', false);
      }
    }
  }
};

export default raffleSalesReport;
