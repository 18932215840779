<template>
  <div id="default-layout">
    <Sidebar v-if="signedIn()" />
    <Navbar v-if="signedIn()" />
    <div class="default-layout">
      <slot />
    </div>
  </div>
</template>

<script>
import store from '@/store';
import Sidebar from '@/components/Sidebar';
import Navbar from '@/components/Navbar';

export default {
  components: {
    Navbar,
    Sidebar
  },
  methods: {
    signedIn: () => {
      return store.state.user;
    }
  }
};
</script>

<style>
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-7.container {
    max-width: 885px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1439px) {
  .mb-7.container {
    max-width: 1085px;
  }
}
@media screen and (min-width: 1439px) and (max-width: 1550px) {
  .mb-7.container {
    max-width: 1330px;
  }
}
@media screen and (min-width: 62em) {
  .default-layout > .container {
    max-width: calc(100% - 8rem);
  }
}
</style>
