<template>
  <div>
    <div>
      <Alert v-if="error" variant="red" icon="exclamation" class="flex justify-center items-center"
        >Error loading Stripe Connected Account: {{ errorMessage }}</Alert
      >
      <p
        v-if="canViewStripeBanner && showStripeBanner && !error"
        class="border border-red-300 text-center bg-red-400 text-xl mb-5 text-black p-5"
      >
        Your Stripe account requires attention.
        <span class="ml-2 align-baseline underline space-x-5">
          <a href="https://help.rafflebox.ca/stripe-connect-update" class="text-white">Stripe connect update</a>
          <a href="https://help.rafflebox.ca/stripe-connect-merchant-setup" class="text-white"
            >Stripe connect merchant setup</a
          >
        </span>
      </p>
    </div>
    <b-container class="mb-7">
      <b-row>
        <b-col id="dashboard">
          <h1 v-if="sessionUser">Welcome {{ sessionUser.name }}</h1>
          <Dashboard />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import OrganizationServiceV2 from '@/lib/organization-service-v2';
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';
import Dashboard from '@/components/Dashboard';
import Alert from '@/components/ui/Alert.vue';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';

export default {
  components: {
    Dashboard,
    Alert
  },
  data() {
    return {
      sessionUser: null,
      futureRequirements: null,
      showError: false,
      errorMessage: '',
      error: false,
      canViewStripeBanner: false
    };
  },
  created: async function () {
    this.canViewStripeBanner = await unleashFeatureEnabled(UnleashKeys.ShowStripeBanner);

    this.sessionUser = await getAuth().sessionUser();

    const organization = await OrganizationServiceV2.retrieveOrganization(this.sessionUser.organizationUuid);

    if (organization.stripeConnectedAccountId) {
      try {
        const account = await OrganizationServiceV2.retrieveAccount(organization.stripeConnectedAccountId);

        this.futureRequirements = account.providerData.futureRequirements;
      } catch (error) {
        this.errorMessage = error.response ? error.response.data.errors[0].message : error;
      }
    }
  },
  computed: {
    stripeFutureRequirements() {
      // backwards compatibility with api
      if (!this.futureRequirements) return [];

      const requirementList = [
        ...this.futureRequirements.currently_due,
        ...this.futureRequirements.past_due,
        ...this.futureRequirements.eventually_due
      ];

      return new Set(requirementList);
    },
    showStripeBanner() {
      return this.stripeFutureRequirements.size > 0;
    }
  }
};
</script>

<style scoped>
.container {
  text-align: left;
}

.container-no-sidebar {
  margin-left: auto;
  width: 100%;
}
.card-summary-group {
  padding: 10px;
  width: 250px;
}
.card-summary {
  margin: 10px;
  border-left: 2px solid #cccccc;
  padding-left: 20px;
}
.card-summary-group-title {
  font-weight: bold;
  font-size: 1.5rem;
  color: #5db966;
}
.card-summary-title {
  font-weight: bold;
  font-size: 1rem;
}
</style>
