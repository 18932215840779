import ReportServiceV2 from '@/lib/report-service-v2';
import parseError from '@/lib/parse-error';

const deviceSalesReport = {
  state: () => ({
    report: {},
    loading: false,
    error: null
  }),

  mutations: {
    SET_DEVICE_SUPPORT_SALES: (state, data) => {
      state.report = data;
    },
    SET_LOADING: (state, data) => {
      state.loading = data;
    },
    SET_ERROR: (state, error) => {
      state.error = error;
    }
  },

  actions: {
    getDeviceSalesReport: async ({ commit }, eventId) => {
      try {
        commit('SET_LOADING', true);
        const data = await ReportServiceV2.retrieveDeviceSalesReport(eventId);

        if (data) {
          commit('SET_DEVICE_SUPPORT_SALES', data);
        }
      } catch (error) {
        const formattedError = parseError(error).message;
        commit('SET_ERROR', formattedError);
      } finally {
        commit('SET_LOADING', false);
      }
    }
  }
};

export default deviceSalesReport;
