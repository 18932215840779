import Axios from '@/axios';

export default {
  createWinner: async (eventId, status) => {
    const res = await Axios.post(
      '/event-service/v2/winners',
      { eventId },
      {
        params: status
      }
    );

    return res.data.data;
  },
  createMulitWinner: async (eventId, prizeId, quantity = 1, status) => {
    const res = await Axios.post('/event-service/v2/winners', { eventId, prizeId, quantity }, { params: { status } });

    return res.data.data;
  },
  retrieveWinner: async (winnerId) => {
    const res = await Axios.get(`/event-service/v2/winners/${winnerId}`);

    return res.data.data;
  },
  listWinners: async (eventId, query = {}, contentType = 'application/json') => {
    const res = await Axios.get('/event-service/v2/winners', {
      params: { eventId, ...query },
      data: {},
      headers: {
        'Content-Type': contentType
      }
    });

    if (contentType === 'text/csv') {
      return res.data;
    }

    return res.data.data;
  },
  updateWinner: async (winnerId, data) => {
    return Axios.patch(`/event-service/v2/winners/${winnerId}`, data);
  }
};
