import config from '@/config';

export const Environments = {
  PRODUCTION: 'production',
  TEST: 'test',
  DEVELOPMENT: 'development'
};

export const isEnvironment = (environments) => {
  if (!environments) {
    return false;
  }

  // convert to array if it is not
  const envArray = Array.isArray(environments) ? environments : [environments];

  return envArray.includes(config.ENV);
};
