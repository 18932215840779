<template>
  <div
    class="loading-spinner flex items-center justify-center rounded-full bg-gradient-to-tr animate-spin"
    :class="[outerSizeClasses, variantClasses]"
  >
    <div class="rounded-full" :class="[innerSizeClasses, innerColor]"></div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'LoadingSpinner',
  props: {
    size: {
      type: String,
      default: 'md',
      validator: (value) => ['xs', 'sm', 'md', 'lg'].includes(value)
    },
    variant: {
      type: String,
      default: 'default',
      validator: (value) => ['default', 'success', 'danger', 'warning', 'secondary', 'tertiary', 'info'].includes(value)
    },
    contrastSafe: {
      type: Boolean,
      default: false
    },
    innerColor: {
      type: String,
      default: ' bg-white'
    }
  },
  computed: {
    variantClasses() {
      if (this.contrastSafe) {
        return ' from-slate-300  via-slate-50  to-white';
      }

      switch (this.variant) {
        case 'success':
          return ' from-green-600  to-green-300';
        case 'danger':
          return ' from-red-600  to-red-300';
        case 'warning':
          return ' from-yellow-600  to-yellow-300';
        case 'secondary':
          return ' from-blue-600  to-blue-300';
        case 'tertiary':
          return ' from-indigo-600  to-indigo-300';
        case 'info':
          return ' from-purple-600  to-purple-300';
        case 'default':
          return ' from-gray-400  to-gray-200';
        default:
          return ' from-gray-400  to-gray-200';
      }
    },
    innerSizeClasses() {
      switch (this.size) {
        case 'xs':
          return ' w-3  h-3';
        case 'sm':
          return ' w-5  h-5';
        case 'md':
          return ' w-8  h-8';
        case 'lg':
          return ' w-11  h-11';
        default:
          return ' w-8  h-8';
      }
    },
    outerSizeClasses() {
      switch (this.size) {
        case 'xs':
          return ' w-5  h-5';
        case 'sm':
          return ' w-8  h-8';
        case 'md':
          return ' w-11  h-11';
        case 'lg':
          return ' w-14  h-14';
        default:
          return ' w-11  h-11';
      }
    }
  }
};
</script>
