<template>
  <b-container>
    <b-card class="card-login shadow rounded mx-auto">
      <b-card-body class="position-static">
        <div class="text-center">
          <slot name="logo" />
        </div>
        <h4 v-if="title" class="app-title">{{ title }}</h4>

        <Alert variant="red" v-if="loginFailed">
          <strong>Login failed.</strong> Having trouble signing in? Email
          <b-link href="mailto:help@rafflebox.ca" class="support-link" target="_blank" rel="noopener">
            help@rafflebox.ca
          </b-link>
        </Alert>

        <b-form class="text-left" @submit.stop.prevent>
          <b-form-group
            label="Email Address"
            label-for="username"
            :invalid-feedback="veeErrors.first('username')"
            label-sr-only
            class="mb-5"
          >
            <b-form-input
              id="username"
              name="username"
              placeholder="Email Address"
              v-model="username"
              v-validate="{ required: true, email: true }"
              :state="validateState('username')"
              data-vv-as="email address"
            />
          </b-form-group>

          <b-form-group
            label="Password"
            label-for="password"
            :invalid-feedback="veeErrors.first('password')"
            label-sr-only
            class="mb-5"
          >
            <b-form-input
              id="password"
              name="password"
              placeholder="Password"
              type="password"
              v-model="password"
              v-validate="{ required: true, password: true }"
              :state="validateState('password')"
              data-vv-as="password"
            />
          </b-form-group>

          <div class="text-center">
            <LoadingButton
              name="login-button"
              type="submit"
              size="large"
              variant="green"
              :loading="busy"
              @onClick="submit"
            >
              Login
            </LoadingButton>
          </div>

          <div class="text-center forgot-password" v-if="showForgotPassword">
            <a href="/forgot-password">Forgot Password?</a>
          </div>
        </b-form>
      </b-card-body>
    </b-card>
  </b-container>
</template>

<script>
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';

import LoadingButton from '@/components/ui/LoadingButton';
import Alert from '@/components/ui/Alert';

export default {
  props: ['title', 'deviceSerialNumber', 'showForgotPassword'],
  components: {
    Alert,
    LoadingButton
  },
  data() {
    return {
      loginFailed: false,
      username: '',
      password: '',
      busy: false
    };
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    async submit() {
      this.loginFailed = false;
      const valid = await this.$validator.validateAll();

      if (!valid) {
        return;
      }

      try {
        this.busy = true;
        const authData = await getAuth().signIn(this.username.trim(), this.password, this.deviceSerialNumber);

        this.$emit('login-success', authData);
      } catch (error) {
        this.loginFailed = true;
        this.$emit('login-error', { errorMessage: error.message });
      } finally {
        this.busy = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.login-logo {
  max-height: 100%;
  max-width: 80%;
  padding-bottom: 1.5rem;
}
.app-title {
  padding-bottom: 2rem;
}
.forgot-password {
  padding-top: 1rem;

  a {
    color: #2c3e50;
  }
}
.card {
  padding: 1.5rem;
  &-body {
    text-align: center;
  }
  &.rounded {
    border-radius: 0.5rem !important;
  }
}
.submit-button {
  display: inline-block !important;
  font-size: 1.25rem !important;
  margin: 2vh 0 1rem;
  padding: 0.6rem 1rem !important;
  color: #fff !important;
  background: #54b75b !important;
  text-decoration: none !important;
  border-color: #fff !important;
  border-radius: 0.5rem !important;
}
.support-link {
  color: #721c24;
  text-decoration: underline;
}
.support-link:hover,
.support-link:focus {
  text-decoration: none;
}
@media screen and (max-width: 48em) {
  .app-title {
    font-size: 3.2rem;
  }
  .card-body {
    .login-logo {
      max-width: 100%;
    }
  }
  .card-login {
    padding: 0;
    border: 0;
    box-shadow: none !important;
    .card-body {
      padding: 0;
    }
  }
  .submit-button {
    width: 100%;
  }
}
</style>
