<template>
  <div id="no-sidebar-layout">
    <Sidebar v-if="signedIn()" hideNav />
    <Navbar v-if="signedIn()" />
    <slot />
  </div>
</template>

<script>
import store from '@/store';
import Sidebar from '@/components/Sidebar';
import Navbar from '@/components/Navbar';
export default {
  components: {
    Navbar,
    Sidebar
  },
  methods: {
    signedIn: () => {
      return store.state.user;
    }
  }
};
</script>
