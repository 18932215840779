import OrderServiceV2 from '@/lib/order-service-v2';

const winners = {
  state: () => ({
    winners: [],
    winnerConfirmed: false,
    winningOrder: null,
    loading: false
  }),

  getters: {
    isWinnerConfirmed: (state) => {
      return state.winnerConfirmed;
    },
    winnerId: (state) => {
      // Jackpot winner is an array but is only one item.
      return state.winners[0] ? state.winners[0].id : null;
    },
    winningOrder(state) {
      return state.winningOrder;
    },
    getWinners(state) {
      return state.winners;
    }
  },

  mutations: {
    SET_CONFIRMED: (state, confirmed) => {
      state.winnerConfirmed = confirmed;
    },
    SET_WINNING_ORDER: (state, order) => {
      state.winningOrder = order;
    }
  },

  actions: {
    async getWinningOrder({ commit }, orderId) {
      const order = await OrderServiceV2.retrieveOrder(orderId);

      if (order) {
        commit('SET_WINNING_ORDER', order);
      }
    }
  }
};

export default winners;
