import DeviceServiceV2 from '@/lib/device-service-v2';
import parseError from '@/lib/parse-error';

const devices = {
  namespaced: true,
  state: () => ({
    devices: [],
    device: [],
    pagination: [],
    loading: false,
    error: false,
    search: null,
    page: 0,
    pageSize: 20,
    sortBy: 'name',
    sortDir: 'asc',
    organizationId: null
  }),

  getters: {
    getPageCount: (state) =>
      Math.ceil(parseInt(state.pagination.total, 10) / parseInt(state.pagination.pageSize, 10)) || 1,
    getDevice: (state) => state.device,
    getParams: (state) => {
      const params = {
        search: state.search,
        page: state.page,
        pageSize: state.pageSize,
        sortBy: state.sortBy,
        sortDir: state.sortDir
      };

      if (state.organizationId) {
        params.organizationId = state.organizationId;
      }

      return params;
    }
  },

  mutations: {
    SET_DEVICES: (state, devices) => {
      state.devices = devices;
    },
    SET_DEVICE: (state, device) => {
      state.device = device;
    },
    SET_PAGINATION: (state, pagination) => {
      state.pagination = pagination;
    },
    SET_LOADING: (state, loading) => {
      state.loading = loading;
    },
    SET_ERROR: (state, error) => {
      state.error = error;
    },
    SET_SEARCH: (state, search) => {
      state.search = search;
    },
    SET_CURRENT_PAGE: (state, page) => {
      state.page = page;
    },
    SET_PAGE_SIZE: (state, pageSize) => {
      state.pageSize = pageSize;
    },
    SET_SORT_BY: (state, sortBy) => {
      state.sortBy = sortBy;
    },
    SET_SORT_DIR: (state, sortDir) => {
      state.sortDir = sortDir;
    },
    SET_ORGANIZATION_ID: (state, organizationId) => {
      state.organizationId = organizationId;
    }
  },

  actions: {
    listDevices: async ({ commit, getters }) => {
      commit('SET_ERROR', false);
      commit('SET_LOADING', true);

      try {
        const params = getters.getParams;

        const result = await DeviceServiceV2.listDevices(params);

        commit('SET_DEVICES', result.data);
        commit('SET_PAGINATION', result.pagination);
      } catch (error) {
        commit('SET_ERROR', parseError(error).message);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    readDevice: async ({ commit }, deviceId) => {
      commit('SET_ERROR', false);
      commit('SET_MODAL_ERROR', null, { root: true });
      commit('SET_MODAL_LOADING', true, { root: true });

      try {
        const result = await DeviceServiceV2.readDevice(deviceId);

        commit('SET_DEVICE', result);
      } catch (error) {
        commit('SET_ERROR', parseError(error).message);
      } finally {
        commit('SET_MODAL_LOADING', false, { root: true });
      }
    },
    updateDevice: async ({ commit, dispatch }, { deviceId, body, isModal }) => {
      try {
        await DeviceServiceV2.updateDevice(deviceId, body);

        // ensure device gets refreshed after an update
        dispatch('readDevice', deviceId);
      } catch (error) {
        const formattedError = parseError(error).message;
        isModal ? commit('SET_MODAL_ERROR', error, { root: true }) : commit('SET_ERROR', formattedError);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    setError({ commit }, error) {
      commit('SET_ERROR', error);
    },
    setOrganizationId({ commit }, organizationId) {
      commit('SET_ORGANIZATION_ID', organizationId);
    }
  }
};

export default devices;
