const modals = {
  state: () => ({
    loading: false,
    error: null
  }),

  mutations: {
    SET_MODAL_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_MODAL_ERROR(state, error) {
      state.error = error;
    }
  },

  actions: {
    stopModalLoading({ commit }) {
      commit('SET_MODAL_LOADING', false);
    },
    setModalError({ commit }, error) {
      commit('SET_MODAL_ERROR', error);
    }
  }
};

export default modals;
