// This keeps a count of active tabs, and will share the token between them via localStorage
// It will clear the token if the last tab is closing
// It supports a single tab refresh by storing the token in both sessionStorage and localStorage

// The exported useMultiTabSession must execute before the Vuex store is initialized
const useMultiTabSessions = () => {
  const localStorage = window.localStorage;
  const sessionStorage = window.sessionStorage;

  // When loading a tab, increase tabCount
  const tabCount = Number(localStorage.getItem('tabCount'));
  localStorage.setItem('tabCount', (tabCount + 1).toString());

  const sessionToken = sessionStorage.getItem('token');
  const localToken = localStorage.getItem('token');

  const loggedOut = localStorage.getItem('loggedOut') === 'true';

  const localUser = localStorage.getItem('user');
  const sessionUser = sessionStorage.getItem('user');

  if (!sessionToken && localToken) {
    // If there is a token in local storage, but not session storage
    // then this is a new tab, and we should add the token to session storage
    sessionStorage.setItem('token', localToken);
    sessionStorage.setItem('user', localUser ?? '');
  } else if (sessionToken && !localToken && !loggedOut) {
    // if there is a token in session storage, but not local storage
    // the user refreshed their only open tab and we should add it back to local storage
    // unless the user logged out in another tab
    localStorage.setItem('token', sessionToken);
    localStorage.setItem('user', sessionUser ?? '');
  }

  window.addEventListener('beforeunload', () => {
    let tabCount = Number(localStorage.getItem('tabCount'));

    tabCount = tabCount - 1;

    // This is the last tab closing, so remove token from local storage
    // if the user is reloading, it will be refreshed from session storage
    if (tabCount < 1) {
      localStorage.removeItem('token');
      localStorage.removeItem('tabCount');
      localStorage.removeItem('user');
    } else {
      localStorage.setItem('tabCount', tabCount.toString());
    }
  });
};

export default useMultiTabSessions;
