import Axios from '@/axios';

export default {
  addDevice: async (body) => {
    const response = await Axios.post(`/device-service/v2/devices`, body);

    return response.data.data;
  },
  listDevices: async (params = {}) => {
    const response = await Axios.get(`/device-service/v2/devices`, {
      params
    });

    return response.data.data;
  },
  readDevice: async (id) => {
    const response = await Axios.get(`/device-service/v2/devices/${id}`);

    return response.data.data;
  },
  syncDevices: async () => {
    const response = await Axios.post(`/device-service/v2/devices/sync`, undefined);

    return response.data;
  },
  updateDevice: async (deviceId, params) => {
    const response = await Axios.patch(`/device-service/v2/devices/${deviceId}`, params);

    return response.data;
  },
  addDeviceUser: async (deviceId, userId) => {
    const response = await Axios.post(`/device-service/v2/devices/${deviceId}/users`, { userId });

    return response.status === 204;
  },
  removeDeviceUser: async (deviceId, userId) => {
    const response = await Axios.delete(`/device-service/v2/devices/${deviceId}/users/${userId}`);

    return response.status === 204;
  },
  createCashDrop: async (data) => {
    const response = await Axios.post(`/device-service/v2/cash-drops`, data);

    return response.id;
  },
  listCashDrops: async (eventId, deviceId) => {
    const response = await Axios.get(`/device-service/v2/cash-drops`, {
      params: {
        eventId,
        deviceId,
        sortBy: 'createdAt',
        pageSize: 100
      }
    });

    return response.data;
  },
  updateCashDrop: async (cashDropId, data) => {
    const response = await Axios.patch(`/device-service/v2/cash-drops/${cashDropId}`, data);

    return response.status === 204;
  },
  deleteCashDrop: async (cashDropId) => {
    await Axios.delete(`/device-service/v2/cash-drops/${cashDropId}`);
  },
  bulkMoveDevices: async (deviceIds, organizationId) => {
    const response = await Axios.patch(`/device-service/v2/devices`, {
      deviceIds,
      organizationId
    });

    return response.data;
  }
};
