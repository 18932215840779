import Axios from '@/axios';

const route = '/event-service/v2/prizes';

export default {
  listPrizes: async (params = {}) => {
    const result = await Axios.get(route, { params });

    return result.data.data;
  },
  readPrize: async (prizeId) => {
    const result = await Axios.get(`${route}/${prizeId}`);

    return result.data.data;
  },
  addPrize: async (body) => {
    const result = await Axios.post(route, body);

    return result.data.data;
  },
  updatePrize: async (prizeId, body) => {
    const result = await Axios.patch(`${route}/${prizeId}`, body);

    return result.data.data;
  },
  updatePrizeOrder: async (params) => {
    const result = await Axios.patch(route, params);
    return result.data.data;
  },
  deletePrize: async (prizeId) => {
    const result = await Axios.delete(`${route}/${prizeId}`);

    return result.data.data;
  }
};
