export default {
  ENV: process.env.VUE_APP_ENV,
  API_URL: process.env.VUE_APP_API_URL,
  PAYMENT_API_URL: process.env.VUE_APP_PAYMENT_API_URL,
  VERSION: 'v1.0.' + process.env.VUE_APP_GIT_COMMIT,
  GIT_COMMIT: process.env.VUE_APP_GIT_COMMIT,
  DEPLOY_TIME: process.env.VUE_APP_DEPLOY_TIME,
  RAFFLEBOX_URL: process.env.VUE_APP_RAFFLEBOX_URL,
  CHECKOUT_URL: process.env.VUE_APP_CHECKOUT_URL,
  RAFFLEBOX_DASHBOARD_URL: process.env.VUE_APP_RAFFLEBOX_DASHBOARD_URL,
  CLIENT: 'DASHBOARD',
  STRIPE_PUBLIC_CA_KEY: process.env.VUE_APP_STRIPE_CA_PUBLIC_KEY,
  STRIPE_PUBLIC_US_KEY: process.env.VUE_APP_STRIPE_US_PUBLIC_KEY,
  MESSAGE_CENTER_URL: process.env.VUE_APP_MESSAGE_CENTER_URL,
  CLOUDFLARE_IMAGE_RENDER_URL: process.env.VUE_APP_CLOUDFLARE_IMAGE_RENDER_URL,
  RAFFLEBOX_IMAGES_URL: process.env.VUE_APP_RAFFLEBOX_IMAGES_URL,
  FIREBASE_WEB_SDK_API_KEY: process.env.VUE_APP_FIREBASE_WEB_SDK_API_KEY,
  FIREBASE_WEB_SDK_AUTH_DOMAIN: process.env.VUE_APP_FIREBASE_WEB_SDK_AUTH_DOMAIN,
  FIREBASE_WEB_SDK_PROJECT_ID: process.env.VUE_APP_FIREBASE_WEB_SDK_PROJECT_ID,
  FIREBASE_WEB_SDK_STORAGE_BUCKET: process.env.VUE_APP_FIREBASE_WEB_SDK_STORAGE_BUCKET,
  FIREBASE_WEB_SDK_MESSAGING_SENDER_ID: process.env.VUE_APP_FIREBASE_WEB_SDK_MESSAGING_SENDER_ID,
  FIREBASE_WEB_SDK_APP_ID: process.env.VUE_APP_FIREBASE_WEB_SDK_APP_ID,
  LOGROCKET_APP_ID: process.env.VUE_APP_LOGROCKET_APP_ID,
  GOOGLE_TAG_MANAGER_CONTAINER_ID: process.env.VUE_APP_GOOGLE_TAG_MANAGER_CONTAINER_ID,
  TICKET_API_URL: process.env.VUE_APP_TICKET_API_URL,
  UNLEASH_CLIENT_KEY: process.env.VUE_APP_UNLEASH_KEY
};
