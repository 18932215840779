<template>
  <div>
    <b-alert v-if="error" show dismissible variant="danger">{{ error }}</b-alert>
    <multiselect
      v-model="selected"
      :options="options"
      label="name"
      track-by="id"
      :searchable="true"
      @search-change="searchChanged"
      :internal-search="false"
      :loading="loading"
      :allow-empty="allowEmpty"
      :placeholder="placeholderText"
      :name="inputName"
      @input="validateRequired"
      @close="validateRequired"
      ref="multiselect"
    >
      <template #noResult> No organizations found matching search. </template>
    </multiselect>
    <p class="invalid-feedback d-block" v-if="showRequiredError">The organization field is required</p>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect';

import OrganizationService from '@/lib/organization-service-v2';
export default {
  components: { Multiselect },
  props: {
    value: null,
    defaultOption: null,
    inputName: {
      type: String
    },
    allowEmpty: {
      type: Boolean,
      default: true
    },
    placeholderText: {
      type: String,
      default: 'Find an organization'
    }
  },
  data() {
    return {
      error: null,
      loading: false,
      options: [],
      selected: null,
      initialized: false,
      showRequiredError: false
    };
  },
  watch: {
    selected: function (value) {
      if (this.initialized) {
        if (value !== null) {
          this.$emit('input', this.selected.id);
          this.$emit('organizationSelected', this.selected);
        } else {
          this.$emit('input', null);
          this.$emit('organizationSelected', null);
        }
      }
    }
  },
  async mounted() {
    this.setOptions([]);

    await this.loadOrganizations();
    await this.loadCurrent();

    this.initialized = true;
  },
  methods: {
    validateRequired() {
      // Manually validate the required aspect of the vue multiselect field
      const multiselect = this.$refs.multiselect;
      const multiselectInput = multiselect.$el.querySelector('.multiselect__tags');
      const multiselectCaret = multiselect.$el.querySelector('.multiselect__select');

      if (!this.allowEmpty && this.selected === null) {
        multiselectInput.className = 'multiselect__tags form-control is-invalid';
        multiselectCaret.className = 'multiselect__select d-none';
        this.showRequiredError = true;
      } else {
        multiselectInput.className = 'multiselect__tags form-control is-valid';
        multiselectCaret.className = 'multiselect__select d-none';
        this.showRequiredError = false;
      }
    },
    async loadCurrent() {
      if (!this.value && !this.defaultOption) {
        return;
      }
      this.loading = true;

      const organization = await OrganizationService.retrieveOrganization(this.value || this.defaultOption);

      this.selected = organization;

      this.loading = false;
    },

    async searchChanged(query) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(() => this.loadOrganizations(query), 300);
    },

    async loadOrganizations(query) {
      this.loading = true;

      const params = {
        sortBy: 'name',
        sortDir: 'asc',
        pageSize: 100
      };

      if (query) {
        params.search = query;
      }

      try {
        const response = await OrganizationService.listOrganizations(params);

        const options = [];

        for (const organization of response.data) {
          options.push(organization);
        }

        this.setOptions(options);
      } catch (error) {
        this.error = error;
      }
      this.loading = false;
    },

    setOptions(options) {
      let result = [];

      if (this.defaultOption) {
        result.push(this.defaultOption);
      }

      result = [...result, ...options];

      this.options = result;
    },
    reset() {
      this.selected = null;
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
