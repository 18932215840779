import Axios from '@/axios';
import AwsAxios from 'axios';

const getS3UploadURL = async (eventId, params) => {
  const response = await Axios.post(`/event-service/v2/events/${eventId}/s3-upload-url`, params);
  return response.data.data.url;
};

export default {
  retrieveEvent: async (eventId) => {
    const response = await Axios.get(`/event-service/v2/events/${eventId}`, {
      params: {
        childEvents: true,
        parentEvent: true,
        activationRequirements: true
      }
    });

    return response.data.data.data;
  },
  retrievePreviousEvent: async (organizationId) => {
    const response = await Axios.get(`/event-service/v2/events`, {
      params: { organizationId, pageSize: 1, page: 0, sortDir: 'desc', sortBy: 'drawDate', status: ['ended'] }
    });
    const eventData = response.data.data.data[0];

    return eventData;
  },

  updateEvent: async (eventId, body) => {
    const response = await Axios.patch(`/event-service/v2/events/${eventId}`, body);

    return response.data.data;
  },

  createEvent: async (body) => {
    const response = await Axios.post(`/event-service/v2/events`, body);

    return response.data.data.data;
  },

  listEventMembers: async (params = {}) => {
    const response = await Axios.get(`/event-service/v2/event-members`, {
      params
    });
    return response.data.data;
  },

  retrieveEventMember: async (id) => {
    const response = await Axios.get(`/event-service/v2/event-members/${id}`);

    return response.data.data;
  },

  uploadEventMembers: async (eventId, formData) => {
    await Axios.patch(`/event-service/v2/event-members/upload`, formData);

    return true;
  },

  getLicenseUrl: async (params) => {
    const response = await Axios.post(`/event-service/v2/events/license-upload-url`, params);

    return response.data.data.url;
  },

  getS3UploadURL,

  getIvLogoUrl: async (eventId, params) => {
    return getS3UploadURL(eventId, params);
  },

  uploadFileToS3: async (url, body) => {
    const response = await AwsAxios.put(url, body, {
      headers: {
        'x-amz-server-side-encryption': 'AES256'
      }
    });

    return response;
  },

  listEvents: async (params = {}) => {
    const response = await Axios.get(`/event-service/v2/events`, {
      params
    });

    return response.data.data;
  },

  updateEventMember: async (eventMemberId, body) => {
    await Axios.patch(`/event-service/v2/event-members/${eventMemberId}`, body);
  },

  createEventMember: async (body) => {
    const response = await Axios.post(`/event-service/v2/event-members`, body);

    return response.data.data.id;
  },

  deleteEventMember: async (eventMemberId) => {
    await Axios.delete(`/event-service/v2/event-members/${eventMemberId}`);
  },

  updateMultipleEventMembers: async (body) => {
    await Axios.patch(`/event-service/v2/event-members`, body);
  },

  exportEventMembers: async (eventId) => {
    const response = await Axios({
      url: '/event-service/v2/event-members/export',
      method: 'GET',
      params: { eventId },
      responseType: 'blob'
    });

    return response.data;
  },

  activateEvent: async (eventId) => {
    await Axios.post(`/event-service/v2/events/${eventId}/activate`);
  },

  deactivateEvent: async (eventId) => {
    await Axios.post(`/event-service/v2/events/${eventId}/deactivate`, {});
  },

  deleteEvent: async (eventId) => {
    await Axios.delete(`/event-service/v2/events/${eventId}`);
  },

  closeEvent: async (eventId, winnerId) => {
    await Axios.post(`/event-service/v2/events/${eventId}/close`, { winnerId });
  },

  requestRaffleActivation: async (eventId) => {
    await Axios.post(`/event-service/v2/events/${eventId}/request-activation`);
  },

  listFrequencies: async () => {
    return ['one-off', 'weekly', 'bi-weekly', 'monthly', 'quarterly', 'multiple'];
  },

  copyRaffle: async (eventId, body) => {
    const response = await Axios.post(`/event-service/v2/events/${eventId}/copy`, body);

    return response.data.data;
  },
  endRaffle: async (eventId) => {
    const response = await Axios.post(`/event-service/v2/events/${eventId}/end`);
    return response.data.data;
  }
};
