import Axios from '@/axios';

export default {
  embedQuestion: async (questionId, params) => {
    const response = await Axios.get(`/report-service/reports/embed/question/${questionId}`, {
      params
    });

    return response.data.data;
  },
  embedDashboard: async (dashboardId, params) => {
    const response = await Axios.get(`/report-service/reports/embed/dashboard/${dashboardId}`, {
      params
    });

    return response.data.data;
  },
  salesByMembers: async (params) => {
    const response = await Axios.get('/report-service/reports/sales-by-event-members', {
      params
    });

    return response.data.data;
  },
  salesByEvents: async (params) => {
    const response = await Axios.get('/report-service/reports/sales-by-events', {
      params
    });

    return response.data.data;
  }
};
