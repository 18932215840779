import Axios from '@/axios';

const ordersUrl = `/order-service/v2/orders`;

const listOrders = async (params = {}) => {
  const response = await Axios.get(ordersUrl, {
    params
  });

  return response.data.data;
};

const voidOrder = async (orderId, voidedComment) => {
  const response = await Axios.delete(`${ordersUrl}/void/${orderId}`, { voidedComment });

  return response.data.data;
};

const voidOrders = async (orderIds, voidedComment) => {
  const response = await Axios.post(`${ordersUrl}/void`, { orderIds, voidedComment });

  return response.data.data;
};

const sendTestOrderEmail = async (body) => {
  const response = await Axios.post(`${ordersUrl}/send-test-email`, body);

  return response.data.data;
};

const sendOrderEmail = async (orderId, email) => {
  const response = await Axios.post(`${ordersUrl}/${orderId}/email`, { email });

  return response.data.data;
};

const updateOrder = async (orderId, body) => {
  const response = await Axios.patch(`${ordersUrl}/${orderId}`, body);

  return response.data.data;
};

const updateOrdersToMailed = async (body) => {
  const response = await Axios.patch(`${ordersUrl}`, body);

  return response.data.data;
};

const retrieveOrder = async (orderId) => {
  const response = await Axios.get(`${ordersUrl}/${orderId}`);

  return response.data.data.data;
};

const importOrders = async (body) => {
  const response = await Axios.post(`${ordersUrl}/import`, body);

  return response.data.data;
};

const getSubscriptionAmount = async (subscriptionId) => {
  const orders = (await listOrders({ subscriptionId, pageSize: 52 })).data;

  const subscriptionAmountCents = orders.reduce(
    (accumulator, currentOrder) => accumulator + currentOrder.amountPaidCents,
    0
  );

  return subscriptionAmountCents / 100;
};

const getActiveSubscriptionOrder = async (subscriptionId) => {
  const orders = (await listOrders({ subscriptionId, pageSize: 52 })).data;

  return orders.filter((order) => order.status === 'ACTIVE')[0];
};

const getOrderGroup = async (orderGroupId) => {
  const response = await Axios.get(`/order-service/v2/order-groups/${orderGroupId}`);

  return response.data.data;
};

const updateOrderGroup = async (orderGroupId, body) => {
  const response = await Axios.patch(`/order-service/v2/order-groups/${orderGroupId}`, body);

  return response.data.data;
};

export default {
  listOrders,
  voidOrder,
  voidOrders,
  sendTestOrderEmail,
  sendOrderEmail,
  updateOrder,
  updateOrdersToMailed,
  retrieveOrder,
  importOrders,
  getSubscriptionAmount,
  getActiveSubscriptionOrder,
  getOrderGroup,
  updateOrderGroup
};
