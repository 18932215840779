<template>
  <BarChart
    title="Total Sales - Last 30 Days"
    :fetchData="getSalesReport"
    :series="series"
    chartId="totalSalesLast30Days"
    csvFilename="Total Sales - Last 30 Days"
    headerCategory="Date"
    @error="handleError"
  />
</template>

<script>
import ReportService from '@/lib/report-service-v2';
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';
import BarChart from '@/components/ui/BarChart.vue';
import { format } from 'date-fns';

export default {
  components: {
    BarChart
  },
  data() {
    return {
      series: [],
      errorMessage: null,
      loading: true
    };
  },
  methods: {
    async getSalesReport() {
      try {
        this.loading = true;
        this.organizationId = (await getAuth().sessionUser()).organizationUuid;
        const result = await ReportService.retrieveTotalSalesReport({ orgId: this.organizationId });

        // Map report data keys to X and Y and format dates/currency as such
        const mappedReportData = result.data.map(({ date: x, dailySalesCents: y }) => {
          const formattedX = format(new Date(x), 'PP');
          const formattedY = y / 100;
          return { y: formattedY, x: formattedX };
        });

        // Update chart data
        this.series = [{ name: 'Amount Sold', data: mappedReportData }];
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      } finally {
        this.loading = false;
      }
    },
    handleError(error) {
      this.errorMessage = this.parseError(error).message;
    }
  }
};
</script>
