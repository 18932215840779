import Axios from '@/axios';

export default {
  listStations: async (params = {}) => {
    const response = await Axios.get(`/goldrush-service/stations`, {
      params
    });

    return response.data.data;
  },
  updateStation: async (stationId, requestBody) => {
    const response = await Axios.patch(`/goldrush-service/stations/${stationId}`, requestBody);

    return response.data.data;
  }
};
