<template>
  <button
    :class="`group inline-flex items-center ${sizeButton} border ${variantButton} rounded-lg focus:ring-2`"
    :type="type"
    @click="onClick"
    :disabled="disabled"
  >
    <div
      v-if="loading"
      :class="`spinner-border animate-spin inline-block ${sizeSpinner} border-2 rounded-full`"
      role="status"
    >
      <span class="sr-only">Loading...</span>
    </div>

    <div class="flex flex-nowrap items-center" :class="loading ? 'ml-2' : ''">
      <slot></slot>
    </div>
  </button>
</template>

<script>
export default {
  name: 'LoadingButton',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'button'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'default',
      validator: (value) =>
        [
          'default',
          'outline',
          'green',
          'green-outline',
          'red',
          'red-outline',
          'blue',
          'blue-outline',
          'yellow',
          'yellow-outline',
          'purple',
          'purple-outline'
        ].includes(value)
    },
    size: {
      type: String,
      default: 'medium',
      validator: function (value) {
        return ['small', 'medium', 'large'].indexOf(value) !== -1;
      }
    }
  },
  computed: {
    variantButton() {
      if (!this.disabled) {
        switch (this.variant) {
          case 'outline':
            return 'text-gray-600 border-gray-600 hover:bg-gray-600 hover:text-white focus:ring-gray-300';
          case 'green':
            return 'text-white bg-green-600 hover:text-green-600 hover:bg-white hover:border-green-600 focus:ring-green-300';
          case 'green-outline':
            return 'text-green-600 border-green-600 hover:bg-green-600 hover:text-white focus:ring-green-300';
          case 'red':
            return 'text-white bg-red-600 hover:text-red-600 hover:bg-white hover:border-red-600 focus:ring-red-300';
          case 'red-outline':
            return 'text-red-600 border-red-600 hover:bg-red-600 hover:text-white focus:ring-red-300';
          case 'blue':
            return 'text-white bg-blue-600 hover:text-blue-600 hover:bg-white hover:border-blue-600 focus:ring-blue-300';
          case 'blue-outline':
            return 'text-blue-600 border-blue-600 hover:bg-blue-600 hover:text-white focus:ring-blue-300';
          case 'yellow':
            return 'text-white bg-yellow-600 hover:text-yellow-600 hover:bg-white hover:border-yellow-600 focus:ring-yellow-300';
          case 'yellow-outline':
            return 'text-yellow-600 border-yellow-600 hover:bg-yellow-600 hover:text-white focus:ring-yellow-300';
          case 'purple':
            return 'text-white bg-purple-600 hover:text-purple-600 hover:bg-white hover:border-purple-600 focus:ring-purple-300';
          case 'purple-outline':
            return 'text-purple-600 border-purple-600 hover:bg-purple-600 hover:text-white focus:ring-purple-300';
          default:
            return 'text-white bg-gray-600 hover:text-gray-600 hover:bg-white hover:border-gray-600 focus:ring-gray-300';
        }
      } else {
        return 'text-gray-300 bg-gray-200 cursor-not-allowed';
      }
    },
    sizeSpinner() {
      switch (this.size) {
        case 'small':
          return 'w-4 h-4';
        case 'large':
          return 'w-5 h-5';
        default:
          return 'w-5 h-5';
      }
    },
    sizeButton() {
      switch (this.size) {
        case 'small':
          return 'py-1.5 px-2 text-sm';
        case 'large':
          return 'py-3 px-5 text-lg';
        default:
          return 'py-2 px-4';
      }
    }
  },
  methods: {
    onClick() {
      this.$emit('onClick');
    }
  }
};
</script>
