import { parseISO, formatDistance } from 'date-fns';
import _ from 'lodash';
import {
  formatCurrency,
  formatNumber,
  formatPostal,
  formatPhone,
  formatDateTime,
  formatBoolean
} from '@rafflebox-technologies-inc/rafflebox-lib';
import enableFeature from '@/lib/enable-feature.js';
import renderImage from '@/lib/render-image';
import parseError from '@/lib/parse-error';
import sleep from '@/lib/sleep';

const sessionUserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';

const mixin = {
  data() {
    return {
      sessionUserTimeZone
    };
  },
  methods: {
    enableFeature,
    formatPostal,
    formatCurrency,
    formatNumber,
    formatDateTime,
    parseISO,
    renderImage,
    formatPhone,
    formatBoolean,
    parseError,
    sleep,
    formatCurrencyWithDash(val) {
      if (Number(val)) {
        return this.formatCurrency(val / 100);
      } else {
        return '-';
      }
    },
    formatNumberWithDash(val) {
      if (Number(val)) {
        return this.formatNumber(val);
      } else {
        return '-';
      }
    },
    formatDistance: (val) => {
      if (_.isDate(val)) {
        return formatDistance(new Date(), val);
      } else if (_.isString(val)) {
        try {
          return formatDistance(new Date(), parseISO(val));
        } catch (error) {
          console.log(error);
        }
      }
      return '';
    },
    formatUuid: (val) => {
      return val && val.length >= 6 ? val.slice(0, 6).toUpperCase() : val;
    },
    // Return null if string is empty. Handy for submitting to the API.
    isStringEmpty: (val) => {
      return typeof val === 'string' && val.trim() === '' ? null : val;
    }
  }
};

export default mixin;
