import ReportServiceV2 from '@/lib/report-service-v2';

const eventSalesReport = {
  state: () => ({
    data: {},
    loading: false
  }),

  getters: {},

  mutations: {
    SET_EVENT_SALES: (state, data) => {
      state.data = data;
    }
  },

  actions: {
    getEventSales: async ({ commit }, eventId, refresh) => {
      const data = await ReportServiceV2.retrieveEventSalesReport(eventId, { refresh });

      if (data) {
        commit('SET_EVENT_SALES', data);
      }
    }
  }
};

export default eventSalesReport;
