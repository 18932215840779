import Vue from 'vue';

import PrizeService from '@/lib/prize-service';
import EventService from '@/lib/event-service-v2';
import WinnerServiceV2 from '@/lib/winner-service-v2';
import { parseISO, isAfter, isBefore } from 'date-fns';

const prizeStore = {
  state: () => ({
    prizes: [],
    prizeWinners: {},
    winnerStates: {},
    pickingBatchWinners: [],
    eventId: '',
    loading: false
  }),

  getters: {
    getPrizeList: (state) => state.prizes,
    getPrizeWinners: (state) => (prizeId) => {
      return state.prizeWinners[prizeId] || [];
    },
    getPrizeState: (_state, getters) => (prizeId, status) => {
      const prizeWinners = getters.getPrizeWinners(prizeId);

      const prizeState = prizeWinners.find((winner) => {
        return winner.status === status;
      });

      return prizeState ? true : false;
    },
    showProgressBar: (state) => (prizeId) => {
      return state.pickingBatchWinners.includes(prizeId);
    },
    isLoading: (state) => state.loading,
    isEarlyBird: (_state, getters) => (prizeId, ticketSalesEnd) => {
      const selectedPrize = getters.getPrize(prizeId);
      if (selectedPrize) {
        const eventDraw = parseISO(ticketSalesEnd);
        const prizeDate = parseISO(selectedPrize.drawDate);

        return isBefore(prizeDate, eventDraw);
      } else {
        return false;
      }
    },
    getPrize: (state) => (prizeId) => {
      return state.prizes.find((prize) => prize.id === prizeId);
    },
    displayPickWinner: (_state, _getters, rootState) => (winners, prizeDrawDate) => {
      let chosen = [];
      const today = new Date();
      const prizeDate = prizeDrawDate ? parseISO(prizeDrawDate) : parseISO(rootState.event.endDate);

      if (winners && winners.length) {
        // Display if there is no picked winner.
        chosen = winners.filter((item) => {
          return item.status === 'confirmed';
        });
      }

      if (isAfter(today, prizeDate) && chosen.length === 0) {
        return true;
      }

      return false;
    }
  },

  mutations: {
    SET_PRIZES: (state, prizes) => {
      state.prizes = prizes;

      // remove batch winner prizes that are completed from the pickingBatchWinners array
      for (const prize of prizes) {
        if (state.pickingBatchWinners.includes(prize.id) && prize.picksCompleted === prize.quantity) {
          const index = state.pickingBatchWinners.indexOf(prize.id);
          state.pickingBatchWinners.splice(index, 1);
        } else if (
          prize.picksCompleted > 0 &&
          prize.picksCompleted !== prize.quantity &&
          !state.pickingBatchWinners.includes(prize.id)
        ) {
          state.pickingBatchWinners.push(prize.id);
        }
      }
    },
    SET_EVENT_ID: (state, eventId) => {
      state.eventId = eventId;
    },
    TOGGLE_LOADING: (state) => {
      state.loading = !state.loading;
    },
    SET_PRIZE_WINNERS: (state, winners) => {
      for (const winner of winners) {
        if (state.winnerStates[winner.id] === winner.status) continue;

        if (state.prizeWinners[winner.prizeId] && state.prizeWinners[winner.prizeId].length) {
          // state.prizeWinners[winner.prizeId] guaranteed to be of size 1 or greater
          const prizeWinner = state.prizeWinners[winner.prizeId].find((prizeWinner) => prizeWinner.id === winner.id);

          if (prizeWinner) {
            const index = state.prizeWinners[winner.prizeId].indexOf(prizeWinner);
            Vue.set(state.prizeWinners[winner.prizeId], index, winner);
          } else {
            state.prizeWinners[winner.prizeId].push(winner);
          }

          state.winnerStates[winner.id] = winner.status;
        } else {
          Vue.set(state.prizeWinners, winner.prizeId, [winner]);
          state.winnerStates[winner.id] = winner.status;
        }
      }
    },
    SET_PICKING_BATCH_PRIZE_STATE: (state, prizeId) => {
      state.pickingBatchWinners.push(prizeId);
    },
    SET_WINNER_STATES: (state, winnerStates) => {
      state.winnerStates = winnerStates;
    }
  },

  actions: {
    listPrizes: async ({ commit }, eventId) => {
      commit('SET_EVENT_ID', eventId);
      commit('TOGGLE_LOADING');
      const params = {
        eventId,
        pageSize: 1000
      };
      const data = await PrizeService.listPrizes(params);

      commit('SET_PRIZES', data.data);
      commit('TOGGLE_LOADING');
    },
    listPrizeWinners: async ({ state, commit }, payload) => {
      const nonBatchPrizeWinners = [];

      const promises = state.prizes
        .filter((prize) => prize.quantity === 1)
        .map((prize) => WinnerServiceV2.listWinners(payload.eventId, { pageSize: 20, prizeId: prize.id }));

      const prizeWinners = await Promise.all(promises);

      for (const winners of prizeWinners) {
        if (winners.data.length) {
          nonBatchPrizeWinners.push(...winners.data);
        }
      }

      if (nonBatchPrizeWinners.length) {
        commit('SET_PRIZE_WINNERS', nonBatchPrizeWinners);
      }
    },
    updatePickingBatchPrizeState: async ({ commit }, prizeId) => {
      commit('SET_PICKING_BATCH_PRIZE_STATE', prizeId);
    },
    addPrize: async ({ dispatch, state }, prize) => {
      await PrizeService.addPrize(prize);
      dispatch('listPrizes', state.eventId);
    },
    updateGrandPrize: async ({ commit, dispatch, state }, payload) => {
      // Update event, then fetch updated event and store it, then list prizes
      await EventService.updateEvent(payload.eventId, payload.event);
      const updatedEvent = await EventService.retrieveEvent(payload.eventId);
      commit('SET_EVENT', updatedEvent);
      dispatch('listPrizes', state.eventId);
    },
    updatePrize: async ({ dispatch, state }, payload) => {
      const { prizeId, prize } = payload;

      await PrizeService.updatePrize(prizeId, prize);
      dispatch('listPrizes', state.eventId);
    },
    updatePrizeOrder: async ({ dispatch, state }, payload) => {
      await PrizeService.updatePrizeOrder(payload);
      dispatch('listPrizes', state.eventId);
    },
    deletePrize: async ({ state, dispatch }, prizeId) => {
      await PrizeService.deletePrize(prizeId);
      dispatch('listPrizes', state.eventId);
    },
    resetPrizes: ({ commit }) => {
      commit('SET_PRIZES', []);
    },
    resetWinners: ({ state }) => {
      state.prizeWinners = {};
    },
    resetWinnerStates: ({ state }) => {
      state.winnerStates = {};
    }
  }
};

export default prizeStore;
