export const downloadFile = (filename, contents, mimeType) => {
  const file = new Blob([contents], { type: mimeType });

  const downloader = document.createElement('a');
  downloader.download = filename;
  downloader.href = URL.createObjectURL(file);
  downloader.click();
};

export const downloadFormattedCSV = (filename, contents) => {
  downloadFile(filename, ['\ufeff' + contents], 'text/csv');
};
