<template>
  <div>
    <b-alert v-if="error">{{ error }}</b-alert>
    <div class="iframe-container">
      <iframe :src="url" frameborder="0" :width="width" :height="height" allowtransparency></iframe>
    </div>
  </div>
</template>
<script>
import ReportService from '@/lib/report-service';

export default {
  props: {
    eventId: {
      type: String,
      required: false
    },
    height: {
      type: String,
      default: '300',
      required: false
    },
    width: {
      type: String,
      default: '100%',
      required: false
    },
    dashboardId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      url: null,
      error: null
    };
  },
  watch: {
    dashboardNumber: async function () {
      this.refreshReport();
    }
  },
  created() {
    this.refreshReport();
  },
  methods: {
    refreshReport: async function () {
      try {
        const url = await ReportService.embedDashboard(this.dashboardId, { eventId: this.eventId });
        this.url = url.url;
      } catch (error) {
        if (error.response && error.response.data.errors) {
          this.error = error.response.data.errors[0].message;
        } else {
          this.error = error;
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 75%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
