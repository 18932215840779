import Axios from '@/axios';
import store from '@/store';

const organizationSignup = async (body) => {
  const response = await Axios.post(`/organization-service/v2/organizations/sign-up`, body);

  return response.data.data;
};

const createOrganization = async (body) => {
  const response = await Axios.post(`/organization-service/v2/organizations`, body);

  return response.data.data;
};

const retrieveOrganization = async (organizationId) => {
  const response = await Axios.get(`/organization-service/v2/organizations/${organizationId}`);
  // this seems like too many .datas
  return response.data.data.data;
};

const listOrganizations = async (params) => {
  const response = await Axios.get(`/organization-service/v2/organizations/`, {
    params
  });

  return response.data.data;
};

const listAllOrganizations = async () => {
  if (!store.state.organizations?.allOrganizations) {
    await store.dispatch('LOAD_ALL_ORGANIZATIONS');
  }

  return store.state.organizations?.allOrganizations;
};

const retrieveAllOrganizations = async (pageSize = 500) => {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  let organizations = [];

  let response;
  let page = 0;

  do {
    response = await listOrganizations({ page, pageSize });
    organizations = organizations.concat(response.data);
    page += 1;
  } while (response.data.length === pageSize);

  return organizations;
};

const updateOrganization = async (organizationId, body) => {
  const response = await Axios.patch(`/organization-service/v2/organizations/${organizationId}`, body);

  return response.status === 200;
};

const deleteOrganization = async (organizationId) => {
  const response = await Axios.delete(`/organization-service/v2/organizations/${organizationId}`);

  return response.data.data;
};

const createAccount = async (provider) => {
  const response = await Axios.post(`/organization-service/v2/accounts`, {
    provider
  });

  return response.data.data;
};

const createAccountLink = async (accountId, organizationId, countryCode) => {
  const response = await Axios.post(`/organization-service/v2/accounts/${accountId}/links`, {
    countryCode,
    organizationId
  });

  return response.data.data;
};

const retrieveAccount = async (accountId) => {
  const response = await Axios.get(`/organization-service/v2/accounts/${accountId}`);
  return response.data.data;
};

const updateAccount = async (accountId, body) => {
  const response = await Axios.patch(`/organization-service/v2/accounts/${accountId}`, body);

  return response.data.data;
};

const retrieveTransactions = async (accountId, params) => {
  const response = await Axios.get(`/organization-service/v2/accounts/${accountId}/transactions`, { params });

  return response.data.data;
};

const createPayout = async (accountId, body) => {
  const response = await Axios.post(`/organization-service/v2/accounts/${accountId}/payouts`, body);

  return response.data.data;
};

const retrievePayouts = async (accountId, provider, params) => {
  const response = await Axios.get(`/organization-service/v2/accounts/${accountId}/payouts`, {
    params: { provider, ...params }
  });

  return response.data.data;
};

const debitAccount = async (accountId, body) => {
  const response = await Axios.post(`/organization-service/v2/accounts/${accountId}/debit`, body);

  return response.data;
};

const debitAccountQBO = async (accountId, body) => {
  const response = await this.axios.post(`/organization-service/v2/accounts/${accountId}/debit`, body);

  return response.data;
};

const getLogoUploadUrl = async (params) => {
  const response = await Axios.post(`/organization-service/v2/organizations/s3-upload-url`, params);
  return response.data.data.url;
};

const uploadFileToS3 = async (url, body) => {
  const uninterceptedAxiosInstance = Axios.create();

  const response = await uninterceptedAxiosInstance.put(url, body, {
    headers: {
      'x-amz-server-side-encryption': 'AES256'
    }
  });

  return response;
};

const newOrganizationService = {
  organizationSignup,
  createOrganization,
  retrieveOrganization,
  listOrganizations,
  listAllOrganizations,
  retrieveAllOrganizations,
  updateOrganization,
  deleteOrganization,
  createAccount,
  createAccountLink,
  retrieveAccount,
  updateAccount,
  retrieveTransactions,
  createPayout,
  retrievePayouts,
  debitAccount,
  debitAccountQBO,
  getLogoUploadUrl,
  uploadFileToS3
};

export default newOrganizationService;
