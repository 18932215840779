import ReportServiceV2 from '@/lib/report-service-v2';
import parseError from '@/lib/parse-error';
import { downloadFormattedCSV } from '@/lib/download-file';

const ticketSalesReport = {
  state: () => ({
    report: {},
    loading: false,
    downloading: false,
    error: null
  }),

  mutations: {
    SET_REPORT_DATA: (state, data) => {
      state.report = data;
    },
    SET_LOADING: (state, data) => {
      state.loading = data;
    },
    SET_DOWNLOADING: (state, data) => {
      state.downloading = data;
    },
    SET_ERROR: (state, error) => {
      state.error = error;
    }
  },

  actions: {
    getTicketSalesReport: async ({ commit }, eventId) => {
      try {
        commit('SET_LOADING', true);
        const data = await ReportServiceV2.retrieveTicketSalesReport(eventId);

        if (data) {
          commit('SET_REPORT_DATA', data);
        }
      } catch (error) {
        const formattedError = parseError(error).message;
        commit('SET_ERROR', formattedError);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    downloadTicketSalesReport: async ({ commit }, eventId) => {
      try {
        commit('SET_DOWNLOADING', true);
        const data = await ReportServiceV2.retrieveTicketSalesReport(eventId, {}, 'text/csv');
        downloadFormattedCSV(`ticket_sales_${Date.now()}.csv`, data);
      } catch (error) {
        const formattedError = parseError(error).message;
        commit('SET_ERROR', formattedError);
      } finally {
        commit('SET_DOWNLOADING', false);
      }
    }
  }
};

export default ticketSalesReport;
