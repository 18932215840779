<template>
  <button
    type="button"
    :class="['flex items-center w-fit rounded-md font-body font-medium group', color, disabledClasses, sizeClasses]"
  >
    <LoadingSpinner
      v-if="loading"
      size="xs"
      :variant="loadingSpinnerColor.variant"
      :innerColor="loadingSpinnerColor.innerColor"
    />
    <font-awesome-icon v-if="icon && !loading" :icon="[iconFamily, icon]" />
    <span v-if="$slots.default" :class="[icon || loading ? 'ml-2 whitespace-nowrap' : 'whitespace-nowrap']">
      <slot></slot>
    </span>
  </button>
</template>

<script>
import LoadingSpinner from '@/components/rbComponents/LoadingSpinner.vue';
export default {
  name: 'BaseButton',
  components: {
    LoadingSpinner
  },
  props: {
    variant: {
      type: String,
      default: 'secondary',
      validator: (variant) =>
        [
          'secondary',
          'success',
          'danger',
          'info',
          'secondary-outline',
          'success-outline',
          'danger-outline',
          'info-outline'
        ].includes(variant)
    },
    iconFamily: {
      type: String,
      required: false,
      default: 'far',
      validator: (iconFamily) => ['far', 'fas'].includes(iconFamily)
    },
    icon: {
      type: String,
      required: false
    },
    size: {
      type: String,
      required: false,
      default: 'md',
      validator: (size) => ['sm', 'md', 'lg'].includes(size)
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    color() {
      switch (this.variant) {
        case 'success':
          return 'border border-green-600 bg-green-600 text-white hover:bg-green-700 hover:transition ease-out duration-300 focus:ring-2 focus:ring-offset-2 focus:ring-green-600';
        case 'success-outline':
          return 'border border-green-600 text-green-700 hover:text-white hover:bg-green-700 hover:border-green-700 hover:transition ease-out duration-300 focus:ring-2 focus:ring-offset-2 focus:ring-green-600';
        case 'danger':
          return 'border border-red-600 bg-red-600 text-white hover:bg-red-700 hover:transition ease-out duration-300 focus:ring-2 focus:ring-offset-2 focus:ring-red-600';
        case 'danger-outline':
          return 'border border-red-600 text-red-700 hover:text-white hover:bg-red-700 hover:border-red-700 hover:transition ease-out duration-300 focus:ring-2 focus:ring-offset-2 focus:ring-red-600';
        case 'info':
          return 'border border-sky-700 bg-sky-700 text-white hover:bg-sky-800 hover:transition ease-out duration-300 focus:ring-2 focus:ring-offset-2 focus:ring-sky-600';
        case 'info-outline':
          return 'border border-sky-700 text-sky-800 hover:text-white hover:bg-sky-800 hover:border-sky-800 hover:transition ease-out duration-300 focus:ring-2 focus:ring-offset-2 focus:ring-sky-600';
        case 'secondary':
          return 'border border-gray-200 bg-gray-200 hover:bg-gray-300 hover:transition ease-out duration-300 focus:ring-2 focus:ring-offset-2 focus:ring-gray-300';
        case 'secondary-outline':
          return 'border border-gray-200 hover:bg-gray-200 hover:border-gray-200 hover:transition ease-out duration-300 focus:ring-2 focus:ring-offset-2 focus:ring-gray-300';
        default:
          return 'bg-gray-200 hover:bg-gray-300 hover:transition ease-out duration-300 focus:ring-2 focus:ring-offset-2 focus:ring-gray-300';
      }
    },
    loadingSpinnerColor() {
      switch (this.variant) {
        case 'secondary-outline':
          return {
            variant: 'default',
            innerColor: 'bg-white group-hover:bg-gray-200'
          };
        case 'success':
          return {
            variant: 'success',
            innerColor: 'bg-green-600 group-hover:bg-green-700'
          };
        case 'success-outline':
          return {
            variant: 'success',
            innerColor: 'bg-white group-hover:bg-green-700'
          };
        case 'danger':
          return {
            variant: 'danger',
            innerColor: 'bg-red-600 group-hover:bg-red-700'
          };
        case 'danger-outline':
          return {
            variant: 'danger',
            innerColor: 'bg-white group-hover:bg-red-700'
          };
        case 'info':
          return {
            variant: 'default',
            innerColor: 'bg-sky-700 group-hover:bg-sky-800'
          };
        case 'info-outline':
          return {
            variant: 'default',
            innerColor: 'bg-white group-hover:bg-sky-800'
          };
        default:
          return {
            variant: 'default',
            innerColor: 'bg-gray-200 group-hover:bg-gray-300'
          };
      }
    },
    disabledClasses() {
      return this.disabled ? 'opacity-50 cursor-not-allowed pointer-events-none' : '';
    },
    sizeClasses() {
      switch (this.size) {
        case 'sm':
          return 'text-sm px-2 py-1';
        case 'md':
          return 'text-base px-3 py-2';
        case 'lg':
          return 'text-lg px-4 py-3';
        default:
          return 'text-base h-11 px-3 py-2';
      }
    }
  }
};
</script>
