<template>
  <div>
    <label :class="{ 'sr-only': showLabel === 'false' }">Change Organization</label>
    <OrganizationSelectV2 @organizationSelected="organizationChanged" :placeholderText="placeholderText" />
  </div>
</template>
<script>
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';

import OrganizationSelectV2 from '@/components/OrganizationSelectV2.vue';

export default {
  components: { OrganizationSelectV2 },
  props: ['showLabel', 'placeholderText'],
  data() {
    return {};
  },
  methods: {
    async organizationChanged(organization) {
      try {
        await getAuth().switchOrganizations(organization.id);

        this.$emit('success');
      } catch (error) {
        this.$emit('error', error);
      }
    }
  }
};
</script>
