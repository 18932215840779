import LogRocket from 'logrocket';

import config from '../config';

const logrocketAppId = config.LOGROCKET_APP_ID;

const init = () => {
  LogRocket.init(logrocketAppId);
};

const identify = (id, email, params) => {
  LogRocket.identify(id, {
    email: email.toLowerCase(),
    ...params
  });
};

export default {
  init: init,
  identify: identify
};
